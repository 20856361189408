
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCCNZcY_HO4GRB0NXH1QnDMbs9R-_DLaaA",
  authDomain: "nft-mozzi.firebaseapp.com",
  projectId: "nft-mozzi",
  storageBucket: "nft-mozzi.appspot.com",
  messagingSenderId: "734118272656",
  appId: "1:734118272656:web:5d70fec2f45fd73843c215",
  measurementId: "G-YJ04MBYFYW"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app)
const db = getFirestore(app);
const storage = getStorage(app,'gs://nft-mozzi.appspot.com')
export { auth,db,storage}