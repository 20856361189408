import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/nft-list',
      name: 'nft-list',
      component: () => import('@/views/NftList.vue'),
      meta: {
        pageTitle: 'NFT List',
        breadcrumb: [
          {
            text: 'NFT List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/nft-write',
      name: 'nft-write',
      component: () => import('@/views/NftWrite.vue'),
      meta: {
        pageTitle: 'Nft Write',
        breadcrumb: [
          {
            text: 'Nft Write',
            active: true,
          },
        ],
      },
    },
    {
      path: '/nft-detail/:id',
      name: 'nft-detail',
      component: () => import('@/views/NftDetail.vue'),
      meta: {
        pageTitle: 'Nft Detail',
        breadcrumb: [
          {
            text: 'Nft Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/nft-update/:id',
      name: 'nft-update',
      component: () => import('@/views/NftUpdate.vue'),
      meta: {
        pageTitle: 'Nft Update',
        breadcrumb: [
          {
            text: 'Nft Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
