
import { signInWithEmailAndPassword ,signOut } from 'firebase/auth'
import { auth } from '../../firebase/index'
import router from '@/router'
import store from '@/store/index'
export default {
  namespaced: true,
  state: {
    user : null,    
  },
  getters: {},
  mutations: {
    SET_USER(state,user){
      state.user = user
    },
    CLEAR_USER(state){
      state.user = null
    }
  },
  actions: {
    action_set_user({commit},data){
      commit('SET_USER',data)
    },
    async login({commit},data){
      try {
        await signInWithEmailAndPassword(auth,data.userEmail,data.password)
        commit('SET_USER',auth.currentUser)
        store.dispatch('nfts/getNftData')
        router.push('/')
      }catch(error){
        switch(error.code){
          case 'auth/user-not-fount':
            alert('유저정보못찾음')
            break
          default:
            alert(error.code) 
        }
      }
    },
    
    async logout({commit}){
      await signOut(auth)
      commit('CLEAR_USER')
      router.push('/login')
    },
  },
}
